<template>
  <div v-if="nodeId === -1 || nodeId === '-1'" class="section-container">
    <no-permission-section />
  </div>
  <div v-else class="section-component">
    <div v-if="loading" class="section-loading">
      <div class="loading-spinner-small"></div>
      <span>Cargando sabías que...</span>
    </div>
    
    <div v-else-if="error" class="section-error">
      <span class="material-icons-outlined">error_outline</span>
      <span>{{ error }}</span>
    </div>
    
    <div v-else class="section-content sabias-que-content">
      <div class="sabias-que-header-inline">
        <span class="material-icons-outlined sabias-que-icon">lightbulb</span>
        <h4 class="node-title">{{ node.title }}</h4>
      </div>
      
      <div v-if="node.content">
        <div 
          class="node-body" 
          :class="{ 'is-collapsed': !isExpanded }" 
          v-html="node.content">
        </div>
        <button @click="toggleExpand" class="expand-button">
          {{ isExpanded ? 'Leer menos' : 'Leer más...' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import APICaller from '@/components/codeComponents/APICaller.js';
import NoPermissionSection from './NoPermissionSection.vue';

export default {
  name: 'SabiaQueSection',
  components: {
    NoPermissionSection
  },
  mixins: [APICaller],
  props: {
    nodeId: {
      type: [Number, String],
      required: true
    },
    sectionType: {
      type: String,
      default: 'sabias_que'
    }
  },
  data() {
    return {
      node: null,
      loading: true,
      error: null,
      isExpanded: false
    };
  },
  mounted() {
    this.fetchNodeData();
  },
  methods: {
    fetchNodeData() {
      this.loading = true;
      this.error = null;
      
      let success = response => {
        this.node = response.data;
        this.loading = false;
      };
      
      let url = `/api/node/${this.nodeId}`;
      
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('403', 'Error al cargar el sabías que', error => {
        if (error.response && error.response.data && error.response.data.error) {
          this.error = error.response.data.error;
        } else {
          this.error = 'No se pudo cargar el contenido';
        }
        this.loading = false;
      });
      
      this._getAPICall(url, successHandler, failureHandler);
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../informes/CommonStyles.scss';

.section-container {
  width: 100%;
}

.sabias-que-content {
  background-color: var(--color-background);
  border-radius: 8px;
  padding: 20px;
  border-left: 4px solid var(--color-secondary);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
}

.sabias-que-header-inline {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.sabias-que-icon {
  color: var(--color-secondary);
  font-size: 22px;
  margin-right: 8px;
}

.node-title {
  margin: 0;
  font-weight: 600;
}

.node-body {
  line-height: 1.6;
  color: var(--color-text-secondary);

  &.is-collapsed {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.expand-button {
  background: none;
  border: none;
  color: var(--color-secondary);
  cursor: pointer;
  padding: 8px 0 0 0;
  margin-top: 8px;
  font-weight: 600;
  font-size: 0.9rem;
  
  &:hover {
    text-decoration: underline;
  }
}
</style> 