<template>
  <div v-if="nodeId === -1 || nodeId === '-1'" class="section-container">
    <no-permission-section />
  </div>
  <div v-else class="section-component video-section">
    <div v-if="loading" class="section-loading">
      <div class="loading-spinner-small"></div>
      <span>Cargando video...</span>
    </div>
    
    <div v-else-if="error" class="section-error">
      <span class="material-icons-outlined">error_outline</span>
      <span>{{ error }}</span>
    </div>
    
    <div v-else class="section-content video-section-content">
      <div class="video-header-inline">
        <span class="material-icons-outlined video-icon">videocam</span>
        <h4 class="node-title">{{ node.title }}</h4>
      </div>
      
      <div v-if="node.content" class="node-body" v-html="node.content"></div>
      
      <div v-if="node.url_video" class="video-player">
        <iframe 
          :src="formattedVideoUrl" 
          width="100%" 
          height="400" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen>
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import APICaller from '@/components/codeComponents/APICaller.js';
import NoPermissionSection from './NoPermissionSection.vue';

export default {
  name: 'VideoSection',
  components: {
    NoPermissionSection
  },
  mixins: [APICaller],
  props: {
    nodeId: {
      type: [Number, String],
      required: true
    },
    sectionType: {
      type: String,
      default: 'video'
    }
  },
  data() {
    return {
      node: null,
      loading: true,
      error: null
    };
  },
  computed: {
    formattedVideoUrl() {
      if (!this.node || !this.node.url_video) return '';
      
      let finalUrl = ''; // Variable to store the result
      
      // Handle YouTube URLs
      if (this.node.url_video.includes('youtube.com') || this.node.url_video.includes('youtu.be')) {
        let videoId = '';
        
        if (this.node.url_video.includes('youtube.com/watch')) {
          const url = new URL(this.node.url_video);
          videoId = url.searchParams.get('v');
        } else if (this.node.url_video.includes('youtu.be/')) {
          const parts = this.node.url_video.split('/');
          videoId = parts[parts.length - 1].split('?')[0];
        }
        
        if (videoId) {
          finalUrl = `https://www.youtube.com/embed/${videoId}`;
        }
      }
      
      // Handle Vimeo URLs
      else if (this.node.url_video.includes('vimeo.com')) {
        const vimeoId = this.node.url_video.split('/').pop();
        if (vimeoId) {
          finalUrl = `https://player.vimeo.com/video/${vimeoId}`;
        }
      }
      
      // Assign original URL ONLY if it wasn't identified as YouTube or Vimeo
      if (!finalUrl) {
        finalUrl = this.node.url_video; 
      }
      
      return finalUrl;
    }
  },
  mounted() {
    this.fetchNodeData();
  },
  methods: {
    fetchNodeData() {
      this.loading = true;
      this.error = null;
      
      let success = response => {
        this.node = response.data;
        this.loading = false;
      };
      
      let url = `/api/node/${this.nodeId}`;
      
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('403', 'Error al cargar el video', error => {
        if (error.response && error.response.data && error.response.data.error) {
          this.error = error.response.data.error;
        } else {
          this.error = 'No se pudo cargar el contenido';
        }
        this.loading = false;
      });
      
      this._getAPICall(url, successHandler, failureHandler);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../informes/CommonStyles.scss';

.section-container {
  width: 100%;
}

.video-section {
  // Optional: Add specific styles for the video section content wrapper
}

.video-header-inline {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.video-icon {
  color: var(--color-secondary);
  font-size: 22px;
  margin-right: 8px;
}

.node-title {
  margin: 0;
  font-weight: 600;
  // color: var(--color-text-primary); // Already handled by global styles likely
}

.node-body {
  line-height: 1.6;
  color: var(--color-text-secondary);
  margin-bottom: 16px; // Add space before video if text exists
}

.video-player {
  margin-top: 16px; // Adjusted margin
  border-radius: 8px;
  overflow: hidden;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); // Consider removing if theme handles elevation
  border: 1px solid var(--color-outline); // Use outline color for border
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style> 