<template>
  <div v-if="nodeId === -1 || nodeId === '-1'" class="section-container">
    <no-permission-section />
  </div>
  <div v-else class="section-component satira-section">
    <div class="section-content satira-section-content">
      <!-- Remove container, apply styles directly to image -->
      <img 
        :src="nodeId" 
        alt="Sátira Bursátil" 
        class="satira-image" 
        @error="handleImageError"
      />
    </div>
  </div>
</template>

<script>
import APICaller from '@/components/codeComponents/APICaller.js';
import NoPermissionSection from './NoPermissionSection.vue';

export default {
  name: 'SatiraSection',
  components: {
    NoPermissionSection
  },
  mixins: [APICaller],
  props: {
    // Accept nodeId, which will actually contain the image URL string
    nodeId: {
      type: String,
      required: true,
      default: ''
    },
    sectionType: {
      // sectionType is technically passed but not used here
    }
  },
  methods: {
    handleImageError(event) {
      console.error('Error loading Satira image:', this.nodeId); // Log the URL (received as nodeId)
      event.target.style.display = 'none'; 
    }
  }
  // No data, computed, mounted, or API calls needed
};
</script>

<style lang="scss" scoped>
@import '../informes/CommonStyles.scss'; 

.section-container {
  width: 100%;
}

.satira-section {
  padding-top: 16px; 
}

.satira-section-content {
  text-align: center; // Center the image block if needed
}

// Styles moved from container to image itself
.satira-image {
  display: block; // Or inline-block if centering with text-align
  width: 75%; // Change max-width to width to force fill horizontally
  height: auto;    
  border-radius: 8px; // Use the container's radius
  border: 1px solid var(--color-outline); // Apply border directly
  overflow: hidden; // Apply overflow if needed (though radius usually handles it)
  margin: 0 auto; // Center the block if display: block
}
</style>