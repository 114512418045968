<template>
  <div class="no-permission-section">
    <span class="material-icons-outlined">lock</span>
    <p>Esta sección solo está disponible para usuarios registrados.</p>
    <div class="action-buttons">
      <button @click="goToLogin" class="action-btn login-btn">Iniciar sesión</button>
      <button @click="goToInformeFlow" class="action-btn register-btn">Registrarse y suscribirse al informe</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoPermissionSection',
  methods: {
    goToLogin() {
      this.$router.push('/app/login');
    },
    goToInformeFlow() {
      this.$router.push('/app/informe/register');
    }
  }
};
</script>

<style lang="scss" scoped>
.no-permission-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px; /* Increased padding */
  border: 1px dashed #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  color: #666;
  text-align: center;
  min-height: 200px; /* Increased min-height */

  .material-icons-outlined {
    font-size: 48px;
    margin-bottom: 15px; /* Increased margin */
    color: #aaa;
  }

  p {
    margin: 0 0 25px 0; /* Added bottom margin */
    font-size: 16px;
  }

  .action-buttons {
    display: flex;
    flex-direction: column; /* Stack buttons vertically on small screens */
    gap: 15px; /* Space between buttons */
    width: 100%;
    max-width: 350px; /* Limit button container width */

    @media (min-width: 600px) { /* Use row layout on larger screens */
      flex-direction: column;
      justify-content: center;
    }
  }

  .action-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s ease;
    width: 100%; /* Full width within the container */

    @media (min-width: 600px) {
      width: auto; /* Auto width on larger screens */
    }

    &.login-btn {
      background-color: var(--primary, #007bff); /* Use CSS variable or default */
      color: white;

      &:hover {
        background-color: var(--primary, #0056b3); /* Darker shade on hover */
      }
    }

    &.register-btn {
      background-color: var(--secondary, #6c757d); /* Use CSS variable or default */
      color: white;

      &:hover {
        background-color: var(--secondary, #5a6268); /* Darker shade on hover */
      }
    }
  }
}
</style> 