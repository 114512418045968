<template>
  <div v-if="nodeId === -1 || nodeId === '-1'" class="section-container">
    <no-permission-section />
  </div>
  <div v-else class="section-component direccionalidad-charts-section">
    <div v-if="initialLoading" class="section-loading">
      <div class="loading-spinner-small"></div>
      <span>Cargando datos del informe...</span>
    </div>
    <div v-else-if="initialError" class="section-error">
       <span class="material-icons-outlined">error_outline</span>
       <span>{{ initialError }}</span>
    </div>

    <div v-else class="charts-grid">
      <!-- Chart 1: Histograma 1 (Últimos 7 días) -->
      <div class="chart-container">
        <h5 class="chart-title">Nuevos Máximos/Mínimos (7 días)</h5>
        <div v-if="hist1Loading" class="chart-loading">
          <div class="loading-spinner-small"></div><span>Cargando...</span>
        </div>
        <div v-else-if="hist1Error" class="chart-error">
          <span class="material-icons-outlined">error_outline</span><span>{{ hist1Error }}</span>
        </div>
        <div v-else :id="chartIdHist1" class="chart-target"></div>
      </div>

      <!-- Chart 2: Histograma 2 (Últimas 8 semanas) -->
      <div class="chart-container">
        <h5 class="chart-title">Nuevos Máximos/Mínimos (8 semanas)</h5>
        <div v-if="hist2Loading" class="chart-loading">
          <div class="loading-spinner-small"></div><span>Cargando...</span>
        </div>
        <div v-else-if="hist2Error" class="chart-error">
          <span class="material-icons-outlined">error_outline</span><span>{{ hist2Error }}</span>
        </div>
        <div v-else :id="chartIdHist2" class="chart-target"></div>
      </div>

      <!-- Chart 3: Pie (Últimos 7 días) -->
      <div class="chart-container">
        <h5 class="chart-title">Distribución Máx/Mín (7 días)</h5>
        <div v-if="pieLoading" class="chart-loading">
          <div class="loading-spinner-small"></div><span>Cargando...</span>
        </div>
        <div v-else-if="pieError" class="chart-error">
          <span class="material-icons-outlined">error_outline</span><span>{{ pieError }}</span>
        </div>
        <div v-else :id="chartIdPie" class="chart-target"></div>
      </div>

      <!-- Chart 4: Pie Up/Down -->
      <div class="chart-container">
        <h5 class="chart-title">Valores Alcistas/Bajistas</h5>
         <div v-if="pieUpDownLoading" class="chart-loading">
          <div class="loading-spinner-small"></div><span>Cargando...</span>
        </div>
        <div v-else-if="pieUpDownError" class="chart-error">
          <span class="material-icons-outlined">error_outline</span><span>{{ pieUpDownError }}</span>
        </div>
        <div v-else :id="chartIdPieUpDown" class="chart-target"></div>
      </div>
    </div>

    <!-- Optional: Keep the button if needed, or remove it -->
    <!--
    <div class="direccionalidad-footer">
      <button @click="goToDireccionalityPage" class="view-more-button">
        <span class="material-icons-outlined">assessment</span>
        <span>Ver página de direccionalidad</span>
      </button>
    </div>
    -->
  </div>
</template>

<script>
import APICaller from '@/components/codeComponents/APICaller.js';
import Highcharts from 'highcharts';
import NoPermissionSection from './NoPermissionSection.vue';
// Optional: Import Highcharts modules if needed (e.g., exporting)
// import Exporting from 'highcharts/modules/exporting';
// Exporting(Highcharts);

// Apply dark theme similar to widget_portfolio_index
Highcharts.theme = {
    colors: ['#00aedd', '#8085e9', '#8d4654', '#7798BF', '#aaeeee', '#ff0066', '#eeaaee',
        '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
    chart: {
        backgroundColor: 'transparent',
        style: {
            fontFamily: '"Open Sans", sans-serif'
        },
        plotBorderColor: '#606063'
    },
    title: { text: null }, // Hide default titles, we use our own
    subtitle: { text: null },
    xAxis: {
        gridLineColor: 'var(--color-outline, rgba(255, 255, 255, 0.1))',
        labels: { style: { color: 'var(--color-text-secondary, #BFC2CD)' } },
        lineColor: 'var(--color-outline, rgba(255, 255, 255, 0.3))',
        minorGridLineColor: 'rgba(255,255,255,0.07)',
        tickColor: 'var(--color-outline, rgba(255, 255, 255, 0.3))',
        title: { style: { color: '#A0A0A3' } }
    },
    yAxis: {
        gridLineColor: 'var(--color-outline, rgba(255, 255, 255, 0.1))',
        labels: { style: { color: 'var(--color-text-secondary, #BFC2CD)' } },
        lineColor: 'var(--color-outline, rgba(255, 255, 255, 0.3))',
        minorGridLineColor: 'rgba(255,255,255,0.07)',
        tickColor: 'var(--color-outline, rgba(255, 255, 255, 0.3))',
        tickWidth: 1,
        title: { style: { color: '#A0A0A3' } }
    },
    tooltip: {
        backgroundColor: 'rgba(32, 32, 43, 0.9)',
        borderColor: 'rgba(255, 255, 255, 0.3)',
        style: { color: '#E0E0E0' }
    },
    plotOptions: {
        series: {
            dataLabels: { color: '#F0F0F3', style: { fontSize: '13px' } },
            marker: { lineColor: '#333' },
            animation: false, // Disable animation for performance if preferred
        },
        boxplot: { fillColor: '#505053' },
        candlestick: { lineColor: 'white' },
        errorbar: { color: 'white' },
        line: {
            marker: { enabled: false },
            lineWidth: 2,
        },
        pie: {
             allowPointSelect: true,
             cursor: 'pointer',
             dataLabels: {
                 enabled: true,
                 format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                 style: { color: 'var(--color-text-primary, #FFFFFF)' }
             },
             showInLegend: false // Can enable if needed
        }
    },
    legend: {
        enabled: true, // Enable legend for histograms
        itemStyle: { color: 'var(--color-text-secondary, #BFC2CD)' },
        itemHoverStyle: { color: 'var(--color-text-primary, #FFFFFF)' },
        itemHiddenStyle: { color: '#606063' }
    },
    credits: { enabled: false },
    labels: { style: { color: '#707073' } },
    // Add other theme options as needed
};
Highcharts.setOptions(Highcharts.theme);


export default {
  name: 'DirecionalidadSection',
  components: {
    NoPermissionSection
  },
  mixins: [APICaller],
  props: {
    nodeId: {
      type: [Number, String],
      required: true
    },
    // sectionType prop might not be needed anymore unless used elsewhere
    sectionType: {
      type: String,
      default: 'direccionalidad-charts' // Updated default
    }
  },
  data() {
    return {
      // Initial node/date loading state
      informeDate: null, // Will store node.title
      initialLoading: true,
      initialError: null,

      // State for Histograma 1
      hist1Data: null,
      hist1Loading: true,
      hist1Error: null,
      // State for Histograma 2
      hist2Data: null,
      hist2Loading: true,
      hist2Error: null,
      // State for Pie 1
      pieData: null,
      pieLoading: true,
      pieError: null,
      // State for Pie Up/Down
      pieUpDownData: null,
      pieUpDownLoading: true,
      pieUpDownError: null,

      chartInstances: {
          hist1: null,
          hist2: null,
          pie: null,
          pieUpDown: null
      },
      _resizeHandler: null,
    };
  },
  computed: {
      // Generate unique IDs for chart containers
      chartIdHist1() { return `chart-hist1-${this.nodeId || Math.random().toString(36).substring(7)}`; },
      chartIdHist2() { return `chart-hist2-${this.nodeId || Math.random().toString(36).substring(7)}`; },
      chartIdPie() { return `chart-pie-${this.nodeId || Math.random().toString(36).substring(7)}`; },
      chartIdPieUpDown() { return `chart-pieupdown-${this.nodeId || Math.random().toString(36).substring(7)}`; },
  },
  mounted() {
    this.fetchInformeDateAndCharts(); // Start the process
    this.setupResizeListener();
  },
  beforeDestroy() {
      // Clean up chart instances
      Object.values(this.chartInstances).forEach(chart => {
          if (chart) {
              try { chart.destroy(); } catch (e) { console.warn("Chart destroy error:", e); }
          }
      });
      this.chartInstances = { hist1: null, hist2: null, pie: null, pieUpDown: null };

      // Clean up resize listener
      this.removeResizeListener();
  },
  methods: {
    fetchInformeDateAndCharts() {
      this.initialLoading = true;
      this.initialError = null;

      const url = `/api/node/${this.nodeId}`;

      const success = response => {
        // Extract the date (DD-MM-YYYY) from the title
        const fullTitle = response.data?.title;
        if (fullTitle) {
          const dateMatch = fullTitle.match(/(\d{2})-(\d{2})-(\d{4})$/);
          if (dateMatch && dateMatch[1] && dateMatch[2] && dateMatch[3]) {
            const day = dateMatch[1];
            const month = dateMatch[2];
            const year = dateMatch[3];
            // Reformat to YYYY-MM-DD
            const formattedDate = `${year}-${month}-${day}`;
            this.informeDate = formattedDate; // Store the correctly formatted date

            this.initialLoading = false;
            // Now that we have the date, fetch the charts
            this.fetchAllChartData(this.informeDate);
          } else {
             this.initialError = `No se pudo extraer la fecha del título: "${fullTitle}"`;
             this.initialLoading = false;
          }
        } else {
          this.initialError = 'No se pudo obtener el título del informe desde el nodo.';
          this.initialLoading = false;
        }
      };

      const failure = error => {
         this.initialError = this.extractError(error, 'Error al cargar datos del informe');
         this.initialLoading = false;
      };

      const successHandler = new APICaller.SuccessHandler(success);
      // Use a more specific failure handler if needed, or reuse the generic one
      const failureHandler = new APICaller.FailureHandler('DIREC_NODE', 'Error en API Node', failure);
      this._getAPICall(url, successHandler, failureHandler, { loadingOverlay: false });
    },

    fetchAllChartData(date) {
      if (!date) {
          console.error("Cannot fetch chart data without a date.");
          // Optionally set errors for all charts here
          return;
      }
      this.fetchHistograma1(date);
      this.fetchHistograma2(date);
      this.fetchPie(date);
      this.fetchPieUpDown(date);
    },

    // --- Fetch Methods ---

    fetchHistograma1(date) {
      this.hist1Loading = true;
      this.hist1Error = null;
      const url = `/graficos/histograma1/${date}`;

      const success = response => {
        // Assuming API returns { series: [...], categories: [...] }
        this.hist1Data = response.data;
        this.hist1Loading = false;
        this.$nextTick(() => this.renderHistograma1());
      };
      const failure = error => {
        this.hist1Error = this.extractError(error, 'Error al cargar Histograma 1');
        this.hist1Loading = false;
      };

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('DIREC_H1', 'Error en API Histograma 1', failure); // Use code and custom handler
      this._getAPICall(url, successHandler, failureHandler, { loadingOverlay: false });
    },

    fetchHistograma2(date) {
      this.hist2Loading = true;
      this.hist2Error = null;
      const url = `/graficos/histograma2/${date}`;

      const success = response => {
        this.hist2Data = response.data;
        this.hist2Loading = false;
        this.$nextTick(() => this.renderHistograma2());
      };
      const failure = error => {
        this.hist2Error = this.extractError(error, 'Error al cargar Histograma 2');
        this.hist2Loading = false;
      };

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('DIREC_H2', 'Error en API Histograma 2', failure);
      this._getAPICall(url, successHandler, failureHandler, { loadingOverlay: false });
    },

    fetchPie(date) {
      this.pieLoading = true;
      this.pieError = null;
      const url = `/graficos/pie/${date}`;

      const success = response => {
         // Assuming API returns array: [['Label1', value1], ['Label2', value2]]
        this.pieData = response.data;
        this.pieLoading = false;
        this.$nextTick(() => this.renderPie());
      };
      const failure = error => {
        this.pieError = this.extractError(error, 'Error al cargar Pie 1');
        this.pieLoading = false;
      };

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('DIREC_P1', 'Error en API Pie 1', failure);
      this._getAPICall(url, successHandler, failureHandler, { loadingOverlay: false });
    },

    fetchPieUpDown(date) {
      this.pieUpDownLoading = true;
      this.pieUpDownError = null;
      const url = `/graficos/pieupdown/${date}`;

      const success = response => {
        // Assuming API returns array: [['Label1', value1], ['Label2', value2]]
        this.pieUpDownData = response.data;
        this.pieUpDownLoading = false;
        this.$nextTick(() => this.renderPieUpDown());
      };
      const failure = error => {
        this.pieUpDownError = this.extractError(error, 'Error al cargar Pie Up/Down');
        this.pieUpDownLoading = false;
      };

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('DIREC_PUD', 'Error en API Pie Up/Down', failure);
      this._getAPICall(url, successHandler, failureHandler, { loadingOverlay: false });
    },

    // --- Render Methods ---

    renderHistograma1() {
      if (!this.hist1Data || !document.getElementById(this.chartIdHist1)) return;
      if (this.chartInstances.hist1) this.chartInstances.hist1.destroy();

      this.chartInstances.hist1 = Highcharts.chart(this.chartIdHist1, {
        chart: { type: 'column', height: 250 }, // Adjust height as needed
        xAxis: { categories: this.hist1Data.categories, crosshair: true },
        yAxis: { min: 0, title: { text: 'Total' } },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
        series: this.hist1Data.series // Expecting [{ name: 'Max', data: [...]}, { name: 'Min', data: [...]}]
      });
    },

    renderHistograma2() {
       if (!this.hist2Data || !document.getElementById(this.chartIdHist2)) return;
       if (this.chartInstances.hist2) this.chartInstances.hist2.destroy();

       this.chartInstances.hist2 = Highcharts.chart(this.chartIdHist2, {
         chart: { type: 'column', height: 250 },
         xAxis: { categories: this.hist2Data.categories, crosshair: true },
         yAxis: { min: 0, title: { text: 'Total' } },
         tooltip: { /* Same as Hist1 or customize */
             headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
             pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                 '<td style="padding:0"><b>{point.y}</b></td></tr>',
             footerFormat: '</table>',
             shared: true,
             useHTML: true
         },
         plotOptions: { column: { pointPadding: 0.2, borderWidth: 0 } },
         series: this.hist2Data.series
       });
    },

    renderPie() {
        if (!this.pieData || !document.getElementById(this.chartIdPie)) return;
        if (this.chartInstances.pie) this.chartInstances.pie.destroy();

        this.chartInstances.pie = Highcharts.chart(this.chartIdPie, {
          chart: { type: 'pie', height: 250 },
          tooltip: { pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b> ({point.y})' },
          // plotOptions.pie is configured globally in the theme
          series: [{
            name: 'Distribución',
            colorByPoint: true,
            data: this.pieData // Expecting [['Label1', value1], ['Label2', value2]]
          }]
        });
    },

     renderPieUpDown() {
        if (!this.pieUpDownData || !document.getElementById(this.chartIdPieUpDown)) return;
        if (this.chartInstances.pieUpDown) this.chartInstances.pieUpDown.destroy();

        this.chartInstances.pieUpDown = Highcharts.chart(this.chartIdPieUpDown, {
          chart: { type: 'pie', height: 250 },
          tooltip: { pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b> ({point.y})' },
          series: [{
            name: 'Valores',
            colorByPoint: true,
            data: this.pieUpDownData // Expecting [['Label1', value1], ['Label2', value2]]
          }]
        });
    },

    // --- Utility Methods ---

    extractError(error, defaultMessage = 'Error desconocido') {
        if (error.response && error.response.data && error.response.data.error) {
          return error.response.data.error;
        } else if (error.message) {
            return error.message;
        }
        return defaultMessage;
    },

    setupResizeListener() {
        if (this._resizeHandler) return; // Already setup
        let resizeTimeout;
        this._resizeHandler = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                Object.values(this.chartInstances).forEach(chart => {
                    if (chart) {
                        chart.reflow();
                    }
                });
            }, 250); // Debounce resize events
        };
        window.addEventListener('resize', this._resizeHandler);
    },

    removeResizeListener() {
        if (this._resizeHandler) {
            window.removeEventListener('resize', this._resizeHandler);
            this._resizeHandler = null;
        }
    },

    // Optional: If the button is kept
    // goToDireccionalityPage() {
    //   this.$router.push('/app/direccionalidad'); // Adjust route if needed
    // }
  }
};
</script>

<style lang="scss" scoped>
@import '../informes/CommonStyles.scss'; // Keep common styles if needed

.section-container {
  width: 100%;
}

.direccionalidad-charts-section {
  // Inherits styles from .section-component if CommonStyles is imported
  background-color: var(--color-surface, #20202B); // Match dark theme
  padding: 16px 20px;
  border-radius: 8px;
  color: var(--color-text-primary, #FFFFFF);
}

.section-main-title {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 20px;
  color: var(--color-text-primary, #FFFFFF);
  border-bottom: 1px solid var(--color-outline, rgba(255, 255, 255, 0.1));
  padding-bottom: 10px;
}

.charts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); // Responsive grid
  gap: 20px; // Space between charts
}

.chart-container {
  background-color: var(--color-background, #0F0F1A); // Slightly different background for chart cards
  border: 1px solid var(--color-outline, rgba(255, 255, 255, 0.1));
  border-radius: 6px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  min-height: 320px; // Ensure minimum height for chart + title
}

.chart-title {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--color-text-secondary, #BFC2CD);
  margin-bottom: 10px;
  text-align: center;
}

.chart-loading,
.chart-error {
  flex-grow: 1; // Take remaining space
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: var(--color-text-secondary, #BFC2CD);
  min-height: 250px; // Match default chart height
}

.chart-error {
  color: var(--color-error, #F44336);
  .material-icons-outlined {
    font-size: 36px;
    margin-bottom: 8px;
  }
}

.chart-target {
  flex-grow: 1; // Allows chart to fill the container space
  min-height: 250px; // Minimum height for the chart itself
  width: 100%;
}


/* Loading spinner - reuse or define if not global */
.loading-spinner-small {
  border: 4px solid rgba(255, 255, 255, 0.2);
  border-left-color: var(--color-primary, #00aedd);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

/* Remove or style the footer button */
/*
.direccionalidad-footer {
  margin-top: 20px;
  text-align: right;
}

.view-more-button {
  // Styles from original component...
  // Adjust colors to match dark theme if kept
}
*/

/* Deep selector for Highcharts SVG elements - might be needed */
:deep(.highcharts-container),
:deep(.highcharts-root) {
  width: 100% !important;
  height: 100% !important;
}
</style> 