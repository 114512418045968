<template>
  <div v-if="nodeId === -1 || nodeId === '-1'" class="section-container">
    <no-permission-section />
  </div>
  <div v-else class="dicho-section section-component">
    <div v-if="loading" class="section-loading">
      <div class="loading-spinner-small"></div>
      <span>Cargando dicho...</span>
    </div>
    
    <div v-else-if="error" class="section-error">
      <span class="material-icons-outlined">error_outline</span>
      <span>{{ error }}</span>
    </div>
    
    <div v-else-if="node" class="section-content">
      <div class="quote-container">
        <div class="quote-body" v-html="node.dicho_body"></div>
        <div class="quote-author">
          — 
          <a v-if="node.dicho_autor_url" :href="node.dicho_autor_url" target="_blank" rel="noopener noreferrer">
            {{ node.dicho_autor }}
          </a>
          <span v-else>
            {{ node.dicho_autor }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APICaller from '@/components/codeComponents/APICaller.js';
import NoPermissionSection from './NoPermissionSection.vue';

export default {
  name: 'DichoSection',
  components: {
    NoPermissionSection
  },
  mixins: [APICaller],
  props: {
    nodeId: {
      type: [Number, String],
      required: true
    },
    sectionType: {
      type: String,
      default: 'dicho'
    }
  },
  data() {
    return {
      node: null,
      loading: true,
      error: null
    };
  },
  mounted() {
    this.fetchNodeData();
  },
  methods: {
    fetchNodeData() {
      this.loading = true;
      this.error = null;
      
      let success = response => {
        this.node = response.data; 
        this.loading = false;
      };
      
      let url = `/api/node/${this.nodeId}`;
      
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('403', 'Error al cargar el dicho', error => {
        if (error.response && error.response.data && error.response.data.error) {
          this.error = error.response.data.error;
        } else {
          this.error = 'No se pudo cargar el contenido';
        }
        this.loading = false;
      });
      
      this._getAPICall(url, successHandler, failureHandler);
    },
  }
};
</script>

<style lang="scss" scoped>
@import '../informes/CommonStyles.scss';

.section-container {
  width: 100%;
}

.quote-container {
  padding: 15px;
  background-color: var(--color-background);
  border-radius: 8px;
  border-left: 4px solid var(--color-secondary);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.quote-body {
  font-style: italic;
  font-size: 1.1em;
  margin-bottom: 10px;
  color: var(--color-text-primary, #333);

  ::v-deep p {
      margin-bottom: 0;
  }
}

.quote-author {
  text-align: right;
  font-weight: bold;
  font-size: 1em;
  color: var(--color-text-secondary, #555);

  a {
    color: var(--color-secondary);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style> 