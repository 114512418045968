<template>
  <div class="informe-detail-page">
    <!-- Course Ad Header -->
    <div class="course-cursoonline-header">
      <div class="cursoonline-content">
        <div class="cursoonline-text">
          <span class="cursoonline-title">Curso online</span>
          <p>Realiza el curso inicial de Eurekers, en cualquier momento del día y desde cualquier lugar.</p>
          <p class="cursoonline-highlight">Gratuitas las dos primeras horas.</p>
        </div>
        <button @click="goToCourse" class="cursoonline-button mdc-button mdc-button--raised">
          <span class="mdc-button__ripple"></span>
          <span class="mdc-button__label">Ver curso</span>
        </button>
      </div>
    </div>

    <!-- Main Content with Sidebar -->
    <div class="informe-content-columns">
      <!-- Left Side Column (Sidebar) -->
      <div class="left-column">
        <!-- Back Button -->
        <div class="sidebar-nav-container">
          <button @click="goBack" class="back-button">
            <span class="material-icons-outlined">arrow_back</span>
            <span>Volver a Informes</span>
          </button>
        </div>

        <!-- Newsletter Subscription -->
        <div class="newsletter-container" v-if="shouldShowNewsletter">
          <div class="newsletter-card">
            <div class="newsletter-content">
              <h2 class="typo-headline-6">Suscríbete a nuestro informe semanal</h2>
              <div class="newsletter-form-new">
                <input
                  v-model="email"
                  type="email"
                  placeholder="Tu correo electrónico"
                  class="newsletter-input-new"
                />
                <div class="newsletter-checkbox">
                  <input 
                    type="checkbox" 
                    id="newsletter-consent" 
                    v-model="consentChecked"
                  />
                  <label for="newsletter-consent" class="consent-text">
                    Acepto suscribirme a Newsletter *<br>
                    <span class="small-text">Marcando esta casilla acepto que Mercalia Global Market, S.A.U. procese mis datos con el fin de enviarme noticias, eventos y otra información relacionada con las actividades de Mercalia Global Market, S.A.U..</span>
                  </label>
                </div>
                <button
                  @click="subscribeToNewsletter"
                  class="subscribe-button-new"
                  :disabled="isSubscribing || !consentChecked"
                >
                  <span v-if="isSubscribing" class="material-icons spinner">sync</span>
                  <span v-else>Suscribirse</span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Libros de Bolsa Promo -->
        <div class="promo-container">
          <a href="/app/libros-de-bolsa" class="promo-link">
            <img src="/sites/all/themes/Porto_sub/img/informe-semanal/cuanto_dinero_necesito_para_invertir_en_bolsa.png" alt="Libros de Bolsa" class="promo-image">
          </a>
        </div>

        <!-- Archivo Section -->
        <div class="sidebar-section">
          <h3 class="sidebar-title">Archivo</h3>
          <div class="sidebar-content">
            <div v-if="archiveLoading" class="archive-loading">
              <div class="loading-spinner"></div>
            </div>
            <div v-else-if="!groupedInformes || groupedInformes.length === 0" class="empty-placeholder">
              No hay informes archivados.
            </div>
            <div v-else class="archive-tree">
              <div v-for="yearItem in groupedInformes" :key="yearItem.year" class="archive-year">
                <div class="archive-year-header" @click="toggleYear(yearItem.year)">
                  <span class="material-icons">{{ expandedYears[yearItem.year] ? 'expand_more' : 'chevron_right' }}</span>
                  <span>{{ yearItem.year }}</span>
                </div>
                <div v-if="expandedYears[yearItem.year]" class="archive-months">
                  <div v-for="monthItem in yearItem.months" :key="monthItem.month" class="archive-month">
                    <div class="archive-month-header" @click="toggleMonth(yearItem.year, monthItem.month)">
                      <span class="material-icons">{{ expandedMonths[yearItem.year+'-'+monthItem.month] ? 'expand_more' : 'chevron_right' }}</span>
                      <span>{{ monthItem.monthName }}</span>
                    </div>
                    <div v-if="expandedMonths[yearItem.year+'-'+monthItem.month]" class="archive-items">
                      <div v-for="archiveInforme in monthItem.informes" :key="archiveInforme.nid" class="archive-item">
                        <router-link :to="`/app/informe/${formatTitleForUrl(archiveInforme.title)}`" class="archive-link">
                          {{ formatArchiveDate(archiveInforme.title) }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Main Content Column -->
      <div class="main-column">
        <div class="informe-detail-container">
          <div v-if="loading" class="informe-loading">
            <div class="loading-spinner"></div>
            <p>Cargando informe...</p>
          </div>
          
          <div v-else-if="error" class="informe-error">
            <span class="material-icons-outlined error-icon">error_outline</span>
            <p>{{ error }}</p>
            <button @click="goBack" class="mdc-button mdc-button--raised">
              <span class="mdc-button__ripple"></span>
              <span class="mdc-button__label">Volver</span>
            </button>
          </div>
          
          <div v-else-if="informe" class="informe-content">
            <div class="informe-header">
              <h1 class="typo-headline-4">Informe semanal del {{ formatDate(informe.created) }}</h1>
            </div>
            
            <div class="informe-sections-list">
              <div 
                v-for="(nodeIds, sectionName) in informe.subnodes" 
                :key="sectionName"
                class="informe-section"
              >
                <div 
                  class="section-header" 
                >
                  <h3 class="section-title">{{ formatSectionName(sectionName) }}</h3>
                </div>
                
                <div 
                  class="section-content"
                >
                  <!-- Remove conditional logic - Generic component handles all sections -->
                  <component 
                    v-for="nodeId in nodeIds" 
                    :key="nodeId" 
                    :is="getSectionComponent(sectionName)"
                    :nodeId="nodeId" 
                    :sectionType="getSectionType(sectionName)"
                  ></component>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer disclaimer -->
    <div class="informe-disclaimer">
      <p class="disclaimer-text">
        La información contenida en este portal web es meramente informativa y no pretende ser una recomendación para sus inversiones. 
        Cada persona física deberá corroborar y decidir las inversiones más propicias para la gestión de su capital. 
        Los análisis realizados contemplan 2 años históricos para que la información sea más comprensible visualmente. 
        Además las escalas utilizadas son logarítmicas.
      </p>
    </div>
  </div>
</template>

<script>
import APICaller from '@/components/codeComponents/APICaller.js';
import { mapGetters } from 'vuex'; // Import mapGetters

// Import section components
import AccionesRentablesSection from '@/components/informes/AccionesRentablesSection.vue';
import ConsejoSection from '@/components/informes/ConsejoSection.vue';
import DirecionalidadSection from '@/components/informes/DirecionalidadSection.vue';
import GraficosSection from '@/components/informes/GraficosSection.vue';
import PodcastSection from '@/components/informes/PodcastSection.vue';
import ResumenSection from '@/components/informes/ResumenSection.vue';
import SabiaQueSection from '@/components/informes/SabiaQueSection.vue';
import VideoSection from '@/components/informes/VideoSection.vue';
import DefaultSection from '@/components/informes/DefaultSection.vue';
import DichoSection from '@/components/informes/DichoSection.vue';
import SatiraSection from '@/components/informes/SatiraSection.vue';

export default {
  name: 'InformeDetail',
  mixins: [APICaller],
  components: {
    AccionesRentablesSection,
    ConsejoSection,
    DirecionalidadSection,
    GraficosSection,
    PodcastSection,
    ResumenSection,
    SabiaQueSection,
    VideoSection,
    DefaultSection,
    DichoSection,
    SatiraSection
  },
  data() {
    return {
      informe: null,
      loading: true,
      error: null,
      archivedInformes: [],
      groupedInformes: [], // Initialize as array
      expandedYears: {},
      expandedMonths: {},
      archiveLoading: true,
      email: '',
      isSubscribing: false,
      hasAlreadySubscribed: false,
      consentChecked: false
    };
  },
  computed: {
      // Computed properties from InformeMain
      ...mapGetters(['_g_ThemeName', '_g_UserFeatures', '_g_UserId']),
      shouldShowNewsletter() {
        // Show only if user is NOT logged in AND has NOT already subscribed
        let isLoggedIn = this._g_UserId != 0;
        return !isLoggedIn && !this.hasAlreadySubscribed;
      }
  },
  watch: {
      // Watch route changes to refetch data if the informe ID changes
      '$route.params.id': 'fetchInformeData'
  },
  mounted() {
    this.fetchInformeData();
    // Fetch data for sidebar
    this.fetchAllInformes();
    this.checkSubscriptionStatus();
  },
  methods: {
    // --- Informe Detail Methods ---
    fetchInformeData() {
      this.loading = true;
      this.error = null;
      this.informe = null; // Reset informe data

      const informeTitleEncoded = this.$route.params.title; // Get encoded title from route
      if (!informeTitleEncoded) {
        // If no title param, redirect to main list
        this.$router.push('/app/informes'); 
        return;
      }
      
      // Decode the title 
      const informeTitleDecoded = decodeURIComponent(informeTitleEncoded);
      
      // Validate the format (dd-mm-yyyy) before making the API call
      const titleRegex = /^\d{1,2}-\d{1,2}-\d{4}$/;
      if (!titleRegex.test(informeTitleDecoded)) {
          console.warn(`Invalid informe title format in URL: ${informeTitleDecoded}. Redirecting.`);
          this.$router.push('/app/informes');
          return; 
      }
      
      // Proceed with API call using the decoded (but still hyphenated) title
      let success = response => {
        this.informe = response.data;
        this.loading = false;
        // Potentially update archive links if necessary after load
      };
      
      let url = `/api/informe/${informeTitleDecoded}`; // Use hyphenated title in API call
      
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('401', 'Error al cargar el informe', error => {
        // Check for specific error statuses (400: Bad Request, 404: Not Found)
        if (error.response && (error.response.status === 400 || error.response.status === 404)) {
          console.warn(`Informe not found or invalid (Status: ${error.response.status}). Redirecting to list.`);
          this.$router.push('/app/informes');
        } else {
          // Handle other errors (e.g., 500, network error)
          if (error.response && error.response.data && error.response.data.error) {
            this.error = error.response.data.error;
          } else {
            this.error = 'No se pudo cargar el informe. Inténtalo de nuevo más tarde.'; // Generic message
          }
          this.loading = false;
        }
      });
      
      this._getAPICall(url, successHandler, failureHandler);
    },
    
    formatDate(dateString) {
      if (!dateString) return '';
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('es-ES', options);
    },
    
    formatSectionName(sectionName) {
      const actualType = this.extractSectionType(sectionName);
      const displayNames = {
        'acciones_rentables': 'Acciones Rentables',
        'consejo': 'Consejo',
        'direccionalidad': 'Direccionalidad',
        'graficos': 'Gráficos',
        'podcast': 'Podcast',
        'resumen': 'Resumen',
        'sabias_que': '¿Sabías que?',
        'video': 'Video',
        'dicho': 'Dicho del Día',
        'satira': 'Sátira Bursátil'
      };
      return displayNames[actualType] || actualType.replace(/_/g, ' ');
    },
    
    goBack() {
      this.$router.push('/app/informes'); // Navigate back to the main informes list
    },

    goToCourse() {
      // Direct to online.eurekers.com
      window.open('https://online.eurekers.com', '_blank');
    },
    
    getSectionType(sectionName) {
      return this.extractSectionType(sectionName);
    },
    
    getSectionComponent(sectionName) {
      const actualType = this.extractSectionType(sectionName);
      const componentMap = {
        'acciones_rentables': 'AccionesRentablesSection',
        'consejo': 'ConsejoSection',
        'direccionalidad': 'DirecionalidadSection',
        'graficos': 'GraficosSection',
        'podcast': 'PodcastSection',
        'resumen': 'ResumenSection',
        'sabias_que': 'SabiaQueSection',
        'video': 'VideoSection',
        'dicho': 'DichoSection',
        'satira': 'SatiraSection'
      };
      return componentMap[actualType] || 'DefaultSection';
    },
    
    extractSectionType(fieldName) {
      if (fieldName === 'field_wr_satira_ref') {
        return 'satira';
      }
      if (fieldName.startsWith('field_wr_') && fieldName.endsWith('_ref')) {
        return fieldName.replace('field_wr_', '').replace('_ref', '');
      }
      return fieldName;
    },
    
    formatTitleForUrl(title) {
      // Assuming title is in 'dd/mm/yyyy' format
      if (!title) return '';
      const parts = title.split('/');
      if (parts.length === 3) {
        // Return 'dd-mm-yyyy' format, URL encoded
        return encodeURIComponent(`${parts[0]}-${parts[1]}-${parts[2]}`);
      }
      // Fallback for unexpected formats - encode the original title
      return encodeURIComponent(title);
    },

    // --- Sidebar Methods (Copied from InformeMain) ---
    checkSubscriptionStatus() {
      this.hasAlreadySubscribed = localStorage.getItem('hasSubscribedToInformeNewsletter') === 'true';
    },

    fetchAllInformes() {
      this.archiveLoading = true;
      let url = `/api/informes-list`;
      
      const successHandler = new APICaller.SuccessHandler(response => {
        this.archivedInformes = response.data;
        this.groupAndOrganizeInformes();
        this.archiveLoading = false;
      });
      
      const failureHandler = new APICaller.FailureHandler('400', 'Error al cargar el archivo de informes', () => {
        this.archiveLoading = false;
      });
      
      this._getAPICall(url, successHandler, failureHandler);
    },
    
    groupAndOrganizeInformes() {
      const grouped = {};
      this.archivedInformes.forEach(informe => {
        const dateParts = this.extractDateParts(informe.title);
        if (dateParts) {
          const { year, month } = dateParts;
          if (!grouped[year]) grouped[year] = {};
          if (!grouped[year][month]) grouped[year][month] = [];
          grouped[year][month].push(informe);
          const monthKey = `${year}-${month}`;
          if (!(year in this.expandedYears)) {
            this.$set(this.expandedYears, year, false);
          }
          if (!(monthKey in this.expandedMonths)) {
            this.$set(this.expandedMonths, monthKey, false);
          }
        }
      });
      const sortedYears = Object.keys(grouped)
        .map(yearStr => parseInt(yearStr, 10))
        .sort((a, b) => b - a); 
      const finalGroupedData = sortedYears.map(year => {
        const yearData = grouped[year];
        const sortedMonths = Object.keys(yearData)
          .map(monthStr => parseInt(monthStr, 10))
          .sort((a, b) => b - a);
        const monthsData = sortedMonths.map(month => {
          const informes = yearData[month];
          informes.sort((a, b) => {
            const dateA = this.extractDateParts(a.title);
            const dateB = this.extractDateParts(b.title);
            if (dateA && dateB) {
              return dateB.day - dateA.day; 
            }
            return 0;
          });
          return {
            month,
            monthName: this.getMonthName(month),
            informes,
          };
        });
        return {
          year,
          months: monthsData,
        };
      });
      this.groupedInformes = finalGroupedData; 
    },
    
    extractDateParts(title) {
      const dateRegex = /(\d{1,2})\/(\d{1,2})\/(\d{4})/;
      const match = title.match(dateRegex);
      if (match) {
        return {
          day: parseInt(match[1], 10),
          month: parseInt(match[2], 10),
          year: parseInt(match[3], 10)
        };
      }
      return null;
    },
    
    toggleYear(year) {
      this.$set(this.expandedYears, year, !this.expandedYears[year]);
    },
    
    toggleMonth(year, month) {
      const key = `${year}-${month}`;
      this.$set(this.expandedMonths, key, !this.expandedMonths[key]);
    },
    
    getMonthName(month) {
      const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
      ];
      return months[month - 1]; 
    },
    
    formatArchiveDate(title) {
      const dateParts = this.extractDateParts(title);
      if (dateParts) {
        return `${dateParts.day.toString().padStart(2, '0')}/${dateParts.month.toString().padStart(2, '0')}/${dateParts.year}`;
      }
      return title;
    },
    
    subscribeToNewsletter() {
      if (!this.validateEmail(this.email)) {
        this.$vueOnToast.pop('error', 'Error', 'Por favor, introduce un correo electrónico válido');
        return;
      }
      if (!this.consentChecked) {
        this.$vueOnToast.pop('error', 'Error', 'Debes aceptar los términos de la suscripción');
        return;
      }
      this.isSubscribing = true;
      let params = new URLSearchParams();
      params.append('email', this.email);
      let success = (response) => {
        if (response.data.success) {
          localStorage.setItem('hasSubscribedToInformeNewsletter', 'true');
          this.hasAlreadySubscribed = true;
          this.$vueOnToast.pop('success', 'Suscripción completada', 'Te has suscrito correctamente al informe semanal');
        } else {
          this.$vueOnToast.pop('error', 'Error en la suscripción', response.data.error || 'Ha ocurrido un error durante la suscripción');
        }
        this.email = '';
        this.isSubscribing = false;
        this.consentChecked = false;
      };
      let url = '/api/suscribir-informe-simple';
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('401', 'Error al suscribirse al informe semanal', () => {
        this.isSubscribing = false;
      });
      this._postAPICall(url, params, successHandler, failureHandler);
    },
    
    validateEmail(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
};
</script>

<style lang="scss" scoped>
// General Page Layout
.informe-detail-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px;
}

// Course Ad Header Styles
.course-cursoonline-header {
  background: linear-gradient(135deg, var(--color-highlight) 0%, #0056b3 100%); // Add gradient background
  border-radius: 16px; // Larger radius
  padding: 30px; // More padding for header placement
  margin-bottom: 24px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15); // Enhanced shadow
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1); // Subtle border
  overflow: hidden; // For the decorative elements

  // Add decorative elements
  &::before {
    content: '';
    position: absolute;
    top: -20px;
    right: -20px;
    width: 150px;
    height: 150px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 50%;
    z-index: 0;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -30px;
    left: -30px;
    width: 180px;
    height: 180px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 50%;
    z-index: 0;
  }
}

.cursoonline-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  position: relative;
  z-index: 1;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.cursoonline-text {
  color: white; // Use white text on solid highlight background

  .cursoonline-title {
    font-size: 24px;
    font-weight: 700;
    display: block;
    margin-bottom: 12px;
    color: white;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2); // Add text shadow
  }

  p {
    margin: 6px 0;
    font-size: 16px;
    color: white;
    opacity: 0.95;
    max-width: 450px;
    line-height: 1.5;
  }

  .cursoonline-highlight {
    font-weight: 600;
    font-size: 18px;
    color: white;
    background-color: rgba(255, 255, 255, 0.25); // More visible
    padding: 8px 14px;
    border-radius: 8px;
    display: inline-block;
    margin-top: 8px;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15); // Subtle text shadow
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Subtle shadow
  }
}

.cursoonline-button {
  background-color: white;
  color: var(--color-highlight);
  border: none;
  border-radius: 10px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); // Enhanced shadow
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  white-space: nowrap;

  &:hover {
    background-color: white;
    transform: translateY(-3px); // More pronounced hover effect
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  }
}

// Two-column layout
.informe-content-columns {
  display: flex;
  flex-direction: column; // Default is mobile column
  gap: 20px;
  margin-bottom: 24px;
  
  @media (min-width: 992px) { // Desktop layout
    flex-direction: row;
    align-items: flex-start;
  }
}

// Left column (sidebar)
.left-column {
  // Base styles - Desktop styles handled in @media min-width: 992px below
  width: 100%; // Default full width for mobile stacking
}

// Main content column
.main-column {
  // Base styles - Desktop styles handled in @media min-width: 992px below
  width: 100%; // Default full width for mobile stacking
}

// Optionally, adjust newsletter container to be more compact on mobile
.newsletter-container {
  margin-bottom: 20px;
  
  .newsletter-content {
    padding: 16px;
    
    h2 {
      margin-bottom: 12px;
    }
  }
}

// Mobile Layout Ordering (max-width: 991px)
@media (max-width: 991px) {
  .left-column {
    display: contents; // Let children participate in the parent flex order
  }
 
  // Assign order explicitly for mobile view
  // Ensure direct children of left-column are targeted if needed, 
  // but since it's display: contents, these should be treated as siblings of main-column
  .sidebar-nav-container { order: 1; margin-bottom: 16px; }
  .newsletter-container { order: 2; }
  .main-column { order: 3; } // Main content comes third
  .promo-container { order: 4; }
  .sidebar-section { order: 5; }
}

// Desktop Layout Styles (min-width: 992px)
@media (min-width: 992px) {
  .left-column {
    display: block; // Reset display from 'contents'
    width: 25%;
    flex-shrink: 0;
    order: 1; // Explicitly set desktop order
  }
  .main-column {
    display: block; // Ensure display is block
    width: 75%;
    order: 2; // Explicitly set desktop order
  }
 
  // Reset order for elements that had mobile order applied
  // Target them correctly based on structure if needed
  .sidebar-nav-container,
  .newsletter-container,
  .promo-container,
  .sidebar-section {
    order: 0; // Reset order for desktop
  }
}

// --- Styles moved/adapted from original InformeDetail ---
.informe-detail-container {
  // Remove padding/margin if handled by informe-detail-page
  padding: 0; 
  margin: 0;
  max-width: none; // Allow it to fill the main-column
}

// Loading and Error states within main column
.informe-loading, .informe-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  min-height: 300px; // Ensure it takes some space
  background-color: var(--color-surface);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(15, 15, 26, 0.05);
  
  .loading-spinner {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 3px solid rgba(0, 173, 216, 0.2);
    border-top-color: var(--mdc-theme-primary);
    animation: spin 1s infinite linear;
    margin-bottom: 16px;
  }
  
  .error-icon {
    font-size: 48px;
    color: var(--color-error);
    margin-bottom: 16px;
  }
  
  p {
    margin-bottom: 24px;
    color: var(--color-text-secondary);
    text-align: center;
  }
}

// Styles for the actual informe content when loaded
.informe-content {
  background-color: var(--color-surface);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(15, 15, 26, 0.05);
  padding: 24px;

  .informe-header {
    margin-bottom: 32px;
    position: relative;
    
    h1 {
      margin: 0;
      color: var(--color-primary, #00ADD8);
      line-height: 1.3;
      font-size: 28px;
      font-weight: 700;
      position: relative;
      display: inline-block;
    }
    
    // Add decorative accent
    h1::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -10px;
      width: 60px;
      height: 4px;
      background-color: var(--color-highlight, #F5A623);
      border-radius: 2px;
    }
  }

  .informe-sections-list {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .informe-section {
    // Add subtle background and border for section separation
    background-color: var(--color-surface-variant, rgba(0, 0, 0, 0.02));
    border-radius: 8px;
    padding: 0 0 16px 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    background-color: var(--color-surface-variant, rgba(0, 0, 0, 0.02));
    border-bottom: 2px solid var(--color-highlight, #F5A623);
    margin-bottom: 16px;
    border-radius: 8px 8px 0 0;
  }

  .section-title {
    margin: 0;
    color: var(--color-primary); // Use primary color for more emphasis
    font-size: 18px; // Slightly larger title
    font-weight: 600;
  }

  .section-content {
    padding: 0 20px 0 20px;
  }
}

// --- Styles Copied/Adapted from InformeMain for Sidebar ---

// Newsletter section styles
.newsletter-container {
  margin-bottom: 20px;
}

.newsletter-card {
  background-color: var(--color-surface);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(15, 15, 26, 0.05);
}

.newsletter-content {
  padding: 16px;
  
  h2 {
    color: var(--color-primary);
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 18px;
  }
}

.newsletter-form-new {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.newsletter-input-new {
  padding: 12px 16px;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  background-color: var(--color-background);
  color: var(--color-text-primary);
  outline: none;
  box-shadow: 0 0 0 1px var(--color-outline);
  transition: box-shadow 0.2s ease;

  &:focus {
    box-shadow: 0 0 0 2px var(--color-highlight);
  }
}

.newsletter-checkbox {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 8px;
  
  input {
    margin-top: 4px;
    accent-color: var(--color-highlight); // Style checkbox color
  }
  
  label {
    font-size: 14px;
    color: var(--color-text-secondary);
    cursor: pointer;
  }
  
  .small-text {
    font-size: 12px;
    display: block;
    margin-top: 4px;
  }
}

.subscribe-button-new {
  background-color: var(--color-highlight);
  color: var(--color-text-on-highlight);
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease, opacity 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(15, 15, 26, 0.08);

  &:hover:not(:disabled) {
    background-color: var(--highlight-hover);
  }

  &:disabled {
    opacity: 0.7;
    cursor: default;
    background-color: var(--color-disabled); // Use a disabled color
  }

  .spinner {
    animation: spin 1s infinite linear;
  }
}

// Promo image
.promo-container {
  margin-bottom: 20px;
  
  .promo-link {
    display: block;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 12px; // Add radius to link for hover effect area
    overflow: hidden; // Ensure image stays within bounds

    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 12px 20px rgba(15, 15, 26, 0.1); // Enhance shadow on hover
    }
  }
  
  .promo-image {
    display: block; // Prevent extra space below image
    width: 100%;
    height: auto;
    border-radius: 12px; // Match link radius
  }
}

// Sidebar sections (Archive)
.sidebar-section {
  margin-bottom: 20px;
  background-color: var(--color-surface);
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(15, 15, 26, 0.05);
  
  .sidebar-title {
    padding: 12px 16px;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    color: var(--color-primary);
    border-bottom: 1px solid var(--color-outline);
  }
  
  .sidebar-content {
    padding: 12px 16px;
  }
  
  .empty-placeholder {
    color: var(--color-text-secondary);
    font-style: italic;
    text-align: center;
    padding: 12px 0;
    font-size: 14px;
  }
}

// Archive tree styles
.archive-tree {
  font-size: 14px;
}

.archive-loading {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  
  .loading-spinner { // Reusing spinner class
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid var(--color-outline);
    border-top-color: var(--color-highlight);
    animation: spin 1s infinite linear;
  }
}

.archive-year {
  margin-bottom: 8px;
}

.archive-year-header, 
.archive-month-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 0;
  color: var(--color-text-primary);
  transition: color 0.2s ease;
  font-weight: 500; // Slightly bolder headers
  
  &:hover {
    color: var(--color-highlight);
  }
  
  .material-icons {
    font-size: 20px; // Slightly larger icon
    margin-right: 6px;
    transition: transform 0.3s ease; // Add transition for icon rotation
  }
}

.archive-months {
  margin-left: 16px; // Indent months
  padding-left: 10px; // Add padding for visual separation
  border-left: 1px solid var(--color-outline-variant); // Subtle separator line
}

.archive-month {
  margin-bottom: 4px;
}

.archive-items {
  margin-left: 22px; // Indent items further
  padding-left: 10px;
  border-left: 1px solid var(--color-outline-variant);
}

.archive-item {
  padding: 4px 0;
}

.archive-link {
  color: var(--color-text-secondary);
  text-decoration: none;
  transition: color 0.2s ease, background-color 0.2s ease;
  display: block; // Make link take full width for easier clicking
  padding: 4px 8px; // Add some padding
  border-radius: 4px; // Rounded corners
  
  &:hover {
    color: var(--color-highlight);
    background-color: var(--color-active-surface); // Subtle background on hover
  }
  
  // Style for the link corresponding to the *currently viewed* informe
  &.router-link-exact-active { // Use exact active class for precise matching
    color: var(--color-highlight);
    font-weight: 600; // Bolder text
    background-color: var(--color-highlight-light); // Highlight background
  }
}

// Footer disclaimer (Copied from InformeMain styles)
.informe-disclaimer {
  margin-top: 32px;
  padding-top: 16px;
  border-top: 1px solid var(--color-outline);
  
  .disclaimer-text {
    font-size: 12px;
    color: var(--color-text-secondary);
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6; // Slightly more spacing
  }
}

// Keyframes (ensure they exist)
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// Styles for back button in sidebar
.sidebar-nav-container {
  margin-bottom: 20px;
}

.back-button {
  display: flex;
  align-items: center;
  background-color: var(--color-surface);
  border: none;
  color: var(--color-text-primary);
  font-size: 14px;
  font-weight: 500;
  padding: 15px 16px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.2s ease, transform 0.2s ease, box-shadow 0.2s ease;
  box-shadow: 0 2px 8px rgba(15, 15, 26, 0.05);
  
  .material-icons-outlined {
    font-size: 18px;
    margin-right: 8px;
  }
  
  &:hover {
    color: var(--color-highlight);
    background-color: var(--color-active-surface);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(15, 15, 26, 0.08);
  }
}
</style> 