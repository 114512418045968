<template>
  <div v-if="nodeId === -1 || nodeId === '-1'" class="section-container">
    <no-permission-section />
  </div>
  <div v-else class="section-component podcast-section">
    <div v-if="loading" class="section-loading">
      <div class="loading-spinner-small"></div>
      <span>Cargando podcast...</span>
    </div>
    
    <div v-else-if="error" class="section-error">
      <span class="material-icons-outlined">error_outline</span>
      <span>{{ error }}</span>
    </div>
    
    <div v-else class="section-content">
      <h4 class="node-title">{{ node.title }}</h4>
      
      <div v-if="node.content" class="node-body" v-html="node.content"></div>
      
      <div v-if="node.url_podcast" class="podcast-player">
        <iframe 
          :src="node.url_podcast" 
          width="100%" 
          height="232" 
          frameborder="0" 
          allowtransparency="true" 
          allow="encrypted-media">
        </iframe>
      </div>
    </div>
  </div>
</template>

<script>
import APICaller from '@/components/codeComponents/APICaller.js';
import NoPermissionSection from './NoPermissionSection.vue';

export default {
  name: 'PodcastSection',
  components: {
    NoPermissionSection
  },
  mixins: [APICaller],
  props: {
    nodeId: {
      type: [Number, String],
      required: true
    },
    sectionType: {
      type: String,
      default: 'podcast'
    }
  },
  data() {
    return {
      node: null,
      loading: true,
      error: null
    };
  },
  mounted() {
    this.fetchNodeData();
  },
  methods: {
    fetchNodeData() {
      this.loading = true;
      this.error = null;
      
      let success = response => {
        this.node = response.data;
        this.loading = false;
      };
      
      let url = `/api/node/${this.nodeId}`;
      
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('403', 'Error al cargar el podcast', error => {
        if (error.response && error.response.data && error.response.data.error) {
          this.error = error.response.data.error;
        } else {
          this.error = 'No se pudo cargar el contenido';
        }
        this.loading = false;
      });
      
      this._getAPICall(url, successHandler, failureHandler);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../informes/CommonStyles.scss';

.section-container {
  width: 100%;
}

.podcast-section {
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.podcast-player {
  margin-top: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
</style> 