<template>
  <div v-if="nodeId === -1 || nodeId === '-1'" class="section-container">
    <no-permission-section />
  </div>
  <div v-else class="section-component">
    <div v-if="loadingNode" class="section-loading">
      <div class="loading-spinner-small"></div>
      <span>Cargando datos del informe...</span>
    </div>
    
    <div v-else-if="errorNode" class="section-error">
      <span class="material-icons-outlined">error_outline</span>
      <span>{{ errorNode }}</span>
    </div>
    
    <div v-else class="section-content">
      <h4 class="node-title">{{ reportData.title }}</h4>
      
      <!-- Loop through potential charts -->
      <div v-for="chart in charts" :key="chart.id" class="chart-container">
        <div v-if="chart.description" class="chart-description" v-html="chart.description"></div>
        
        <div v-if="chart.loading" class="section-loading">
          <div class="loading-spinner-small"></div>
          <span>Cargando gráfico {{ chart.id }}...</span>
        </div>
        
        <div v-else-if="chart.error" class="section-error">
          <span class="material-icons-outlined">warning_amber</span>
          <span>{{ chart.error }}</span>
      </div>
      
        <!-- Use a plain div as the chart target -->
        <div v-else-if="chart.config" class="chart-wrapper">
          <div :id="chartElementId(chart.id)" class="chart-target"></div>
    </div>
    
        <div v-else class="section-info">
           <span>No se encontraron datos para el gráfico {{ chart.id }}.</span>
        </div>
      </div>
       <div v-if="charts.length === 0 && !loadingNode && !errorNode" class="section-info">
         <span>No hay gráficos configurados para esta sección.</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import APICaller from '@/components/codeComponents/APICaller.js';
import NoPermissionSection from './NoPermissionSection.vue';
import Highcharts from 'highcharts'; // Use Highcharts core
// Stock module might not be strictly necessary if only using Highcharts.chart
import StockModule from 'highcharts/modules/stock'; 
import ExportingModule from 'highcharts/modules/exporting';
import AccessibilityModule from 'highcharts/modules/accessibility';

// Initialize Highcharts modules
StockModule(Highcharts); // Keep for potential future use or if chart() relies on it indirectly
ExportingModule(Highcharts);
AccessibilityModule(Highcharts);

// Optional: Apply a theme if desired
// Highcharts.setOptions({...}); 

export default {
  name: 'GraficosSection',
  components: {
    NoPermissionSection
  },
  mixins: [APICaller],
  props: {
    nodeId: {
      type: [Number, String],
      required: true
    },
    sectionType: {
      type: String,
      default: 'graficos'
    }
  },
  data() {
    return {
      reportData: null, // Will hold the full node data including chart data
      charts: [], // { id, description, config, instance, error (optional) }
      loadingNode: true,
      errorNode: null,
      _resizeHandler: null, 
    };
  },
  mounted() {
    this.fetchNodeData(); // Only need to fetch the combined node data now
    this.setupResizeListener(); 
  },
  beforeDestroy() {
      // Clean up chart instances
      this.charts.forEach(chart => {
          if (chart.instance) {
              try { chart.instance.destroy(); } catch (e) { console.warn("Chart destroy error:", e); }
              chart.instance = null; 
          }
      });
      this.removeResizeListener();
  },
  methods: {
    chartElementId(chartId) {
       // Use a simpler ID structure if nodeId is always present
       return `grafico-section-chart-${this.nodeId}-${chartId}`;
    },

    fetchNodeData() {
      this.loadingNode = true;
      this.errorNode = null;
      this.charts = []; // Reset charts
      
      const url = `/api/node/${this.nodeId}`;
      
      const successHandler = new APICaller.SuccessHandler(response => {
        this.reportData = response.data;
        // console.log('GraficosSection: fetchNodeData successful. Data:', JSON.stringify(this.reportData, null, 2));
        this.loadingNode = false;
        this.processNodeDataAndRenderCharts(); // Process the received data immediately
      });
      
      const failureHandler = new APICaller.FailureHandler('403', 'Error al cargar datos del informe', error => {
        // console.error('GraficosSection: fetchNodeData failed:', error);
        this.errorNode = this.extractErrorMessage(error, 'No se pudo cargar el contenido del informe y los gráficos.');
        this.loadingNode = false;
      });
      
      // Removed loading overlay parameter as it might not be needed now
      this._getAPICall(url, successHandler, failureHandler);
    },
    
    processNodeDataAndRenderCharts() {
      const node = this.reportData;
      if (!node) return;
      
      let chartsFound = false;

      for (let i = 1; i <= 4; i++) {
        const historyDataKey = `grafico_${i}_data`;
        const tickerKey = `grafico_${i}_ticker`;
        const descriptionFieldKey = `field_wr_grafico_${i}_description`;

        const historyData = node[historyDataKey];
        const ticker = node[tickerKey]; // Get ticker passed from backend
        const descriptionField = node[descriptionFieldKey];

        if (historyData && ticker) { // Check if data and ticker exist for this chart index
          chartsFound = true;
          const description = descriptionField?.und?.[0]?.safe_value || descriptionField?.und?.[0]?.value || `Gráfico ${i}`;
          
          let chartConfig = null;
          let chartError = null;

          if (historyData.s === 'ok' && historyData.t && historyData.t.length > 0) {
             chartConfig = this.createChartConfig(ticker, historyData); 
          } else if (historyData.s === 'no_data') {
              chartError = 'No hay datos disponibles para este período.';
              console.warn(`GraficosSection: No data pre-fetched for chart ${i} (${ticker})`);
          } else { // Handle backend errors reported in historyData.s === 'error'
              chartError = historyData.errmsg || 'Error al cargar datos del gráfico desde el backend.';
              console.error(`GraficosSection: Backend error for chart ${i} (${ticker}):`, chartError);
          }

          const chartData = {
            id: i,
            ticker: ticker,
            description: description,
            config: chartConfig, // Can be null if there was an error or no data
            instance: null,
            error: chartError // Store potential error message
          };
          this.charts.push(chartData);

          // If we have a valid config, schedule rendering
          if (chartConfig) {
             this.$nextTick(() => {
                 this.renderChart(chartData); // Pass the chartData object
             });
          }

        } else if (descriptionField) {
             // If only description exists, maybe show description without chart?
             // Or just ignore if no history data/ticker was returned.
             // Current logic only adds to this.charts if historyData and ticker are present.
             // console.log(`GraficosSection: Description found for chart ${i} but no data/ticker.`);
        }
      } // End for loop

      if (!chartsFound) {
          console.log("GraficosSection: No pre-fetched chart data found in node response.");
      }
    },

    createChartConfig(ticker, historyData) {
      // Process only timestamps (t) and closing prices (c)
      const lineData = historyData.t.map((timestamp, index) => {
        return [
          timestamp * 1000, // Backend sends seconds, convert to ms
          Number(historyData.c[index]) || 0 
        ];
      });

      return {
        chart: {
           zooming: { type: 'x' },
           backgroundColor: 'var(--color-background)', // Use CSS variable for background
           style: { fontFamily: 'inherit' } // Inherit font from parent
        },
        title: {
           text: `${ticker} Daily Closing Price`,
           style: { color: 'var(--color-text-primary)' } // Use CSS variable for title
        },
        xAxis: {
           type: 'datetime',
           lineColor: 'var(--color-outline)',
           tickColor: 'var(--color-outline)',
           labels: { style: { color: 'var(--color-text-secondary)' } }
        },
        yAxis: [{
           labels: {
              align: 'right',
              x: -3,
              style: { color: 'var(--color-text-secondary)' }
           },
           title: {
              text: 'Price',
              style: { color: 'var(--color-text-secondary)' }
           },
           gridLineColor: 'var(--color-outline)',
           lineWidth: 1, // Use outline width for axis line
           lineColor: 'var(--color-outline)', 
        }],
        tooltip: {
           backgroundColor: 'var(--color-surface)', // Use CSS variable for tooltip background
           style: { color: 'var(--color-text-primary)' }, // Use CSS variable for tooltip text
           valueDecimals: 2, 
           xDateFormat: '%Y-%m-%d', 
           pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.y}</b><br/>'
        },
        series: [{
           type: 'line',
           name: ticker,
           data: lineData,
           id: ticker + '-line',
           color: 'var(--color-highlight)', // Use CSS variable for line color
           marker: { // Add marker styling for consistency
               symbol: 'circle',
               enabled: false, // Keep markers off by default for line
               states: {
                   hover: { enabled: true }
               }
           }
        }],
         credits: { enabled: false },
         exporting: { enabled: true }
      };
    },

    renderChart(chart) { // Now receives the full chart object
      const targetId = this.chartElementId(chart.id);
      const targetElement = document.getElementById(targetId);

       if (!targetElement) {
           console.error(`GraficosSection: Target element #${targetId} not found for chart ${chart.id}.`);
           // Update the chart object's error state instead of directly manipulating component data
           chart.error = "Error al encontrar el contenedor del gráfico."; 
           chart.config = null; // Ensure config is null to hide wrapper via v-else-if
           return;
       }

       if (!chart.config) { // Config might be null due to no data or backend error
           // console.log(`GraficosSection: No chart config available for chart ${chart.id}, likely due to no data or backend error.`);
           // No need to render if no config
           return; 
       }

       // Destroy previous instance if it exists
       if (chart.instance) {
           try { chart.instance.destroy(); } catch (e) { /* ignore */ }
           chart.instance = null;
       }

       try {
           // console.log(`GraficosSection: Rendering chart ${chart.id} into ${targetId}`);
           chart.instance = Highcharts.chart(targetElement, chart.config);
       } catch (e) {
           console.error(`GraficosSection: Error creating Highcharts instance for chart ${chart.id}:`, e);
           chart.error = "Error al renderizar el gráfico."; 
           chart.config = null; // Clear config on error to hide target div
       }
    },

    setupResizeListener() {
        if (this._resizeHandler) return; 
        let resizeTimeout;
        this._resizeHandler = () => {
            clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                this.charts.forEach(chart => {
                    if (chart.instance) {
                       try { chart.instance.reflow(); } catch(e) { /* ignore */ }
                    }
                });
            }, 250); 
        };
        window.addEventListener('resize', this._resizeHandler);
    },

    removeResizeListener() {
        if (this._resizeHandler) {
            window.removeEventListener('resize', this._resizeHandler);
            this._resizeHandler = null;
        }
    },
    
     extractErrorMessage(error, defaultMessage) {
        if (error.response && error.response.data && error.response.data.error) {
          return error.response.data.error;
        } else if (error.response && error.response.data && error.response.data.message) {
           return error.response.data.message; 
        } else if (error.message) {
           return error.message;
        } else if (typeof error === 'string') { // Handle plain string errors if needed
           return error;
        }
        return defaultMessage;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../informes/CommonStyles.scss';

.section-container {
  width: 100%;
}

.chart-container {
  margin-bottom: 2rem; 
  padding: 1rem;
  border: 1px solid var(--color-outline);
  border-radius: 8px;
  background-color: var(--color-background);
  display: flex; // Use flexbox for layout
  flex-direction: column;
}

.chart-description {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--color-outline);
  font-size: 0.95rem;
  color: var(--color-text-primary);
  ::v-deep p {
    margin-bottom: 0.5em; 
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.chart-wrapper {
   flex-grow: 1; // Allow wrapper to fill space
   min-height: 400px; // Ensure minimum height for the chart target
}

.chart-target {
  width: 100%;
  height: 100%; // Fill the wrapper
}

.section-loading, 
.section-error { // Display error or loading state *instead* of chart-wrapper/target
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 300px; // Give some space
  padding: 1rem;
  text-align: center;
}

// Keep the loading state style specific if needed
.section-loading {
   color: #666;
}

// Style for errors specific to a chart
.section-error {
  color: #dc3545; // Error color (e.g., Bootstrap danger)
   .material-icons-outlined {
     margin-bottom: 0.5rem;
     font-size: 1.5rem;
   }
}

.section-info { // Used if no charts configured OR if a chart has no data
  padding: 1rem;
  text-align: center;
  color: #666;
  background-color: #f9f9f9;
  border-radius: 4px;
  margin-top: 1rem;
  // Make it fill space if needed inside chart-container
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Add specific styles for Highcharts if necessary */
:deep(.highcharts-container), 
:deep(.highcharts-root) {
  width: 100% !important;
  height: 100% !important; 
}

/* Loading spinner */
.loading-spinner-small {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #007bff; // Adjust color if needed
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

</style> 