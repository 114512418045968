<template>
  <div v-if="nodeId === -1 || nodeId === '-1'" class="section-container">
    <no-permission-section />
  </div>
  <div v-else class="section-component consejo-section">
    <div v-if="loading" class="section-loading">
      <div class="loading-spinner-small"></div>
      <span>Cargando consejo...</span>
    </div>
    
    <div v-else-if="error" class="section-error">
      <span class="material-icons-outlined">error_outline</span>
      <span>{{ error }}</span>
    </div>
    
    <div v-else class="section-content">
      <h4 class="node-title">{{ node.title }}</h4>
      
      <div class="consejo-content">
        <div v-if="node.imagen" class="consejo-image">
          <img :src="node.imagen" :alt="node.title">
        </div>
        
        <div v-if="node.content" class="node-body" v-html="node.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import APICaller from '@/components/codeComponents/APICaller.js';
import NoPermissionSection from './NoPermissionSection.vue';

export default {
  name: 'ConsejoSection',
  components: {
    NoPermissionSection
  },
  mixins: [APICaller],
  props: {
    nodeId: {
      type: [Number, String],
      required: true
    },
    sectionType: {
      type: String,
      default: 'consejo'
    }
  },
  data() {
    return {
      node: null,
      loading: true,
      error: null
    };
  },
  mounted() {
    this.fetchNodeData();
  },
  methods: {
    fetchNodeData() {
      this.loading = true;
      this.error = null;
      
      let success = response => {
        this.node = response.data;
        this.loading = false;
      };
      
      let url = `/api/node/${this.nodeId}`;
      
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('403', 'Error al cargar el consejo', error => {
        if (error.response && error.response.data && error.response.data.error) {
          this.error = error.response.data.error;
        } else {
          this.error = 'No se pudo cargar el contenido';
        }
        this.loading = false;
      });
      
      this._getAPICall(url, successHandler, failureHandler);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../informes/CommonStyles.scss';

.section-container {
  width: 100%;
}

.consejo-section {
  margin-top: 16px;
}

.consejo-content {
  margin-top: 16px;
}

.consejo-image {
  float: right;
  width: 200px;
  margin-left: 20px;
  margin-bottom: 20px;
  
  img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 767px) {
    float: none;
    width: 100%;
    margin-left: 0;
    margin-bottom: 16px;
  }
}
</style> 