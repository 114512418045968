<template>
  <div v-if="nodeId === -1 || nodeId === '-1'" class="section-container">
    <no-permission-section />
  </div>
  <div v-else class="section-component default-section">
    <div v-if="loading" class="section-loading">
      <div class="loading-spinner-small"></div>
      <span>Cargando contenido...</span>
    </div>
    
    <div v-else-if="error" class="section-error">
      <span class="material-icons-outlined">error_outline</span>
      <span>{{ error }}</span>
    </div>
    
    <div v-else class="section-content">
      <h4 class="node-title">{{ node.title }}</h4>
      <div class="node-body">
        <!-- Placeholder for content -->
        <p>Contenido por defecto. Se personalizará para cada tipo de sección.</p>
      </div>
    </div>
  </div>
</template>

<script>
import APICaller from '@/components/codeComponents/APICaller.js';
import NoPermissionSection from './NoPermissionSection.vue';

export default {
  name: 'DefaultSection',
  components: {
    NoPermissionSection
  },
  mixins: [APICaller],
  props: {
    nodeId: {
      type: [Number, String],
      required: true
    },
    sectionType: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      node: null,
      loading: true,
      error: null
    };
  },
  mounted() {
    this.fetchNodeData();
  },
  methods: {
    fetchNodeData() {
      this.loading = true;
      this.error = null;
      
      let success = response => {
        this.node = response.data;
        this.loading = false;
      };
      
      let url = `/api/node/${this.nodeId}`;
      
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('403', 'Error al cargar el contenido', error => {
        if (error.response && error.response.data && error.response.data.error) {
          this.error = error.response.data.error;
        } else {
          this.error = 'No se pudo cargar el contenido';
        }
        this.loading = false;
      });
      
      this._getAPICall(url, successHandler, failureHandler);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../informes/CommonStyles.scss';

.section-container {
  width: 100%;
}

.default-section {
  margin-bottom: 16px;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.section-loading, .section-error {
  display: flex;
  align-items: center;
  color: var(--color-text-secondary);
  font-size: 14px;
  padding: 12px 0;
  
  .loading-spinner-small {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid rgba(0, 173, 216, 0.2);
    border-top-color: var(--mdc-theme-primary);
    animation: spin 1s infinite linear;
    margin-right: 10px;
  }
  
  .material-icons-outlined {
    font-size: 20px;
    color: var(--color-error);
    margin-right: 8px;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.node-title {
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 12px;
  color: var(--color-text-primary);
}

.node-body {
  color: var(--color-text-secondary);
  line-height: 1.6;
}
</style> 